import React, { createContext, useEffect, useMemo, useState } from 'react'
import { isBrowser } from '@atomixdesign/rem-scaling'
import { StyledBannerWrapper } from './HomeBanner'

export const HomeBannerContext = createContext<{
  currentBanner: number
}>({
  currentBanner: -1,
})

export const getCurrentBanner = (totalBanner) => {
  const currentBannerValue = isBrowser() ? localStorage.getItem('CurrentBanner') : undefined

  let currentBanner = currentBannerValue && currentBannerValue.length>0? Number.parseInt(currentBannerValue) : 0

  if( currentBanner >= totalBanner){
    currentBanner = 0
  }else{
    currentBanner++
  }

  if (isBrowser()) {
    localStorage.setItem( 'CurrentBanner', currentBanner.toString() )
  }

  return currentBanner
}

export const HomeBanners: React.VFC<{ children: React.ReactNode[] }> = ( { children }) => {
  const totalBanner = useMemo(() => React.Children.count(children) - 1, [children])
  const [currentBanner, setCurrentBanner] = useState(isBrowser() ? -1 : 0)

  useEffect(() => {
    setCurrentBanner(getCurrentBanner(totalBanner))
  }, [totalBanner])

  return (
    <HomeBannerContext.Provider value={{ currentBanner }}>
      <StyledBannerWrapper>
        {children}
      </StyledBannerWrapper>
    </HomeBannerContext.Provider>
  )
}